import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CognitoUserLoggedIn {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognitoAuthService.hasCognitoSession().pipe(
      map((loggedIn) => {
        if (!loggedIn) {
          void this.router.navigate(['login'], {
            queryParams: {
              returnUrl: window.location,
            },
          });
          return false;
        }

        return loggedIn;
      }),
    );
  }
}
